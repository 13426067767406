import { useQuery } from '@tanstack/react-query';
import { Title } from 'components';
import QuestionSectionSkeleton from 'components/skeleton/QuestionSectionSkeleton';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MemberConsultation as MemberConsultationType,
  getMemberConsultation
} from 'services/consultation';

import ConsultationCard from './components/ConsultationCard';

interface MemberConsultationProps {
  consultationId: number;
  onGetMemberConsultation?: (consultation: MemberConsultationType) => void;
}

const MemberConsultation = ({
  consultationId,
  onGetMemberConsultation
}: MemberConsultationProps) => {
  const { t } = useTranslation('customer');

  const {
    data: consultation,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['member-consultation', consultationId],
    queryFn: () => getMemberConsultation(consultationId)
  });

  useEffect(() => {
    if (isSuccess) {
      onGetMemberConsultation?.(consultation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (isLoading) return <QuestionSectionSkeleton />;
  if (!isSuccess) return <div />;

  return (
    <ConsultationCard
      consultation={consultation}
      header={
        <Title variant="h4" component="h3">
          {consultation.status === 'status_scheduled' &&
            t('account.consultation.title')}
          {consultation.status === 'status_to_reschedule' &&
            t('account.consultation.title.lawyerCanceled')}
          {consultation.status === 'status_cancelled' &&
            t('account.consultation.title.canceled')}
          {consultation.status === 'status_completed' &&
            t('account.consultation.title.completed')}
        </Title>
      }
    />
  );
};

export default MemberConsultation;
