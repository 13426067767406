import { useQuery } from '@tanstack/react-query';
import { Chip, Section, Title } from 'components';
import QuestionSectionSkeleton from 'components/skeleton/QuestionSectionSkeleton';
import { useTranslation } from 'react-i18next';
import { getMemberConsultations } from 'services/consultation';
import { QuestionSource } from 'services/question';

import ConsultationCard from './components/ConsultationCard';

interface ScheduledConsultationsProps {
  source?: QuestionSource;
}

const ScheduledConsultations = ({
  source = 'personal'
}: ScheduledConsultationsProps) => {
  const { t } = useTranslation('customer');

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ['member-scheduled-consultations'],
    queryFn: () =>
      getMemberConsultations({ status: 'status_scheduled', source })
  });

  if (isLoading) return <QuestionSectionSkeleton />;
  if (!isSuccess || data.total === 0) return <div />;

  return (
    <Section>
      <Title gutterBottom variant="h2">
        {t('account.consultations.scheduled.title')}
        <Chip color="purple" className="text-lg py-1.5 ml-2">
          {data.total}
        </Chip>
      </Title>
      <div className="flex flex-col gap-4 max-w-[720px]">
        {data.items.map((consultation) => (
          <ConsultationCard consultation={consultation} />
        ))}
      </div>
    </Section>
  );
};

export default ScheduledConsultations;
