import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { Card, Divider } from 'components';
import { CardContent } from 'components/card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MemberConsultation } from 'services/consultation';

import UploadConsultationDocument from 'features/document/UploadConsultationDocument';

import CancelConsultation from '../CancelConsultation';
import ConsultationDay from './ConsultationDay';
import ConsultationDropdown from './ConsultationDropdown';
import ConsultationSummary from './ConsultationSummary';

interface ConsultationCardProps {
  consultation: MemberConsultation;
  header?: React.ReactNode;
}

const ConsultationCard = ({ consultation, header }: ConsultationCardProps) => {
  const { t } = useTranslation('customer');
  const [isCancelConsultationModalOpen, setIsCancelConsultationModalOpen] =
    useState(false);

  const { document } = consultation;

  const hasActions = consultation.can_cancel || consultation.can_reschedule;

  return (
    <Card>
      <CardContent>
        {header}
        {header && <Divider />}
        <Disclosure>
          <div className="flex">
            <ConsultationDay date={new Date(consultation.start_at)} />
            <ConsultationSummary
              date={new Date(consultation.start_at)}
              lawyer={consultation.lawyer}
              document={document}
              canUploadDocument={consultation.can_upload_document}
              triggerDocument={DisclosureButton}
            />
            <div className="hidden flex-col lg:flex items-end text-gray-800 flex-1 text-sm justify-between">
              {t('account.consultation.description.type')}
            </div>
          </div>
          {hasActions && (
            <ConsultationDropdown
              canReschedule={consultation.can_reschedule}
              canCancel={consultation.can_cancel}
              consultationId={consultation.id}
              questionId={consultation.question_id}
              onCancelClick={() => setIsCancelConsultationModalOpen(true)}
            />
          )}
          {/* find a better way to handle containers */}
          {consultation.can_upload_document && (
            <DisclosurePanel
              transition
              className="flex flex-col origin-top transition duration-75 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
            >
              {({ close }) => (
                <UploadConsultationDocument
                  onSuccess={close}
                  consultationId={consultation.id}
                />
              )}
            </DisclosurePanel>
          )}
        </Disclosure>
        <CancelConsultation
          isOpen={isCancelConsultationModalOpen}
          consultationId={consultation.id}
          questionId={consultation.question_id}
          onClose={() => setIsCancelConsultationModalOpen(false)}
        />
      </CardContent>
    </Card>
  );
};

export default ConsultationCard;
